const en = {
    // ADD TO EXCEL
    'toast_email_campaign_test_in_progress': 'Test email is on its way to the test recipients!',
    'email_campaign_is_being_prepared': 'The Email Campaign is being prepared. In a few moments (up to a few minutes) the Email Campaign will start sending.',

    // DO NOT REMOVE - USED BY ETB - DEPRECATED!
    '1.0': '1.0',
    '1.1': '1.1',
    '1.2': '1.2',
    '1.3': '1.3',
    '1.4': '1.4',
    '1.5': '1.5',
    '1.6': '1.6',
    '1.7': '1.7',
    '1.8': '1.8',
    '1.9': '1.9',
    '2.0': '2',
    '2.1': '2.1',
    '2.2': '2.2',
    '2.3': '2.3',
    '2.4': '2.4',
    '2.5': '2.5',
    '2.6': '2.6',
    '2.7': '2.7',
    '2.8': '2.8',
    '2.9': '2.9',

    'Arial': 'Arial',
    'Arial Black': 'Arial Black',
    'Book Antiqua': 'Book Antiqua',
    'Charcoal': 'Charcoal',
    'Comfortaa': 'Comfortaa',
    'Comic Sans': 'Comic Sans',
    'Courier': 'Courier',
    'Courier New': 'Courier New',
    'Gadget': 'Gadget',
    'Geneva': 'Geneva',
    'Georgia': 'Georgia',
    'Helvetica': 'Helvetica',
    'Impact': 'Impact',
    'Lucida Console': 'Lucida Console',
    'Lucida Grande': 'Lucida Grande',
    'Lucida Sans Unicode': 'Lucida Sans Unicode',
    'Monaco': 'Monaco',
    'Open Sans': 'Open Sans',
    'Palatino': 'Palatino',
    'Palatino Linotype': 'Palatino Linotype',
    'Roboto': 'Roboto',
    'Roboto Condensed': 'Roboto Condensed',
    'Roboto Slab': 'Roboto Slab',
    'Source Sans Pro': 'Source Sans Pro',
    'Tahoma': 'Tahoma',
    'Times': 'Times',
    'Times New Roman': 'Times New Roman',
    'Trebuchet': 'Trebuchet',
    'Verdana': 'Verdana',
    'cursive': 'cursive',
    'monospace': 'monospace',
    'sans-serif': 'sans-serif',
    'serif': 'serif',

    // PASTE AFTER THIS POINT!
    '100': '100',
    '200': '200',
    '300': '300',
    '400': '400 (Normal)',
    '500': '500',
    '600': '600',
    '700': '700 (Bold)',
    '800': '800',
    '900': '900',
    'Account': 'Account',
    'Account updated': 'Account updated',
    'Accounts': 'Accounts',
    'Activity': 'Activity',
    'Add criteria': 'Add criteria',
    'Add Email Address': 'Add Email Address',
    'Add item': 'Add item',
    'Add Labels': 'Add Labels',
    'Add Life Event': 'Add Life Event',
    'Add option': 'Add option',
    'Add sorter': 'Add sorter',
    'Address': 'Address',
    'Alignment': 'Alignment',
    'ALL': 'all',
    'All changes will be discarded.': 'All changes will be discarded.',
    'All Properties': 'All Properties',
    'An unexpected error has occurred': 'An unexpected error has occurred',
    'Analytics': 'Analytics',
    'AND': 'and',
    'and_all': 'all',
    'ANY': 'any',
    'April': 'April',
    'ASC': 'asc',
    'Ascending': 'Ascending',
    'audit_created': 'Created on {dateTime} by {actor}',
    'audit_edited': '@:audit_updated',
    'audit_updated': 'Updated on {dateTime} by {actor}',
    'August': 'August',
    'Available Credits': 'Available Credits',
    'Available soon': 'Available soon',
    'Available Soon': 'Available Soon',
    'Back to Categories': 'Back to Categories',
    'Back to Email Campaign': 'Back to Email Campaign',
    'Background': 'Background',
    'Background Color': 'Background Color',
    'Background Image': 'Background Image',
    'Background Image Repeat': 'Background Image Repeat',
    'Background Image Size': 'Background Image Size',
    'Basic': 'Basic',
    'BETWEEN': 'between',
    'Bold': 'Bold',
    'Border': 'Border',
    'Border Radius': 'Border Radius',
    'Bottom': 'Bottom',
    'Branding': 'Branding',
    'Broadcaster': 'Broadcaster',
    'Broadcaster created': 'Broadcaster created',
    'Broadcaster deleted': 'Broadcaster deleted',
    'Broadcaster updated': 'Broadcaster updated',
    'Broadcaster\'s \'from\' email is not verified': 'Email is not verified',
    'Broadcaster\'s \'from\' email is not verified (2)': 'Email is not verified',
    'Broadcaster\'s \'from\' email must be a unique email': 'Email must be unique',
    'Broadcaster\'s \'reply-to\' email is not verified': 'Email is not verified',
    'Broadcaster\'s \'reply-to\' email is not verified (2)': 'Email is not verified',
    'Broadcaster\'s SMS Name must be unique': 'SMS Name must be unique',
    'Browse Campaigns': 'Browse Campaigns',
    'Browse Email Templates': 'Browse Email Templates',
    'Browse Images': 'Browse Images',
    'Browse People': 'Browse People',
    'Builder': 'Builder',
    'BUILDER': 'BUILDER',
    'Button': 'Button',
    'Button Content': 'Button Content',
    'Button Spacing': 'Button Spacing',
    'Button Style': 'Button Style',
    'by': 'by',
    'Call to action': 'Call to action',
    'Call to Action': 'Call to Action',
    'CALL_LOG': 'Logged Call',
    'Campaign': 'Campaign',
    'CAMPAIGN_WEB_LINK_CLICK': 'Campaign Web Link Click',
    'Campaigns': 'Campaigns',
    'Center': 'Center',
    'Change View Size': 'Change View Size',
    'Changes discarded': 'Changes discarded',
    'Choose a file or drag it here': 'Choose a file or drag it here',
    'Choose a title or create a new one.': 'Choose a title or create a new one.',
    'Choose a view': 'Choose a view',
    'City': 'City',
    'Cohabitation': 'Cohabitation',
    'Color': 'Color',
    'Color Primary': 'Color Primary',
    'Color Secondary': 'Color Secondary',
    'Column': 'Column',
    'Column #1': 'Column #1',
    'Column #2': 'Column #2',
    'Column #3': 'Column #3',
    'Column #4': 'Column #4',
    'Column Inner Spacing': 'Column Inner Spacing',
    'Column Width': 'Column Width',
    'Coming Soon': 'Coming Soon',
    'common_action_add': 'Add',
    'common_action_apply': 'Apply',
    'common_action_cancel': 'Cancel',
    'common_action_choose': 'Choose',
    'common_action_close': 'Close',
    'common_action_continue': 'Continue',
    'common_action_create': 'Create',
    'common_action_create_and_edit': 'Create and Edit',
    'common_action_create_and_view': 'Create and View',
    'common_action_create_quick': 'Quick Create',
    'common_action_customize': 'Customize',
    'common_action_delete': 'Delete',
    'common_action_disable': 'Disable',
    'common_action_discard': 'Discard',
    'common_action_display': 'Display',
    'common_action_download': 'Download',
    'common_action_edit': 'Edit',
    'common_action_enable': 'Enable',
    'common_action_export': 'Export',
    'common_action_filter': 'Filter',
    'common_action_import': 'Import',
    'common_action_logout': 'Logout',
    'common_action_okay': 'Okay',
    'common_action_preview': 'Preview',
    'common_action_print': 'Print',
    'common_action_refresh': 'Refresh',
    'common_action_remove': 'Remove',
    'common_action_reset': 'Reset',
    'common_action_return': 'Return',
    'common_action_save': 'Save',
    'common_action_save_as': 'Save as',
    'common_action_search': 'Search',
    'common_action_submit': 'Submit',
    'common_action_use': 'Use',
    'common_pagination_next': 'Next',
    'common_pagination_page_size_label': '{object} per page',
    'common_pagination_previous': 'Previous',
    'common_pagination_results_phrase': 'no results | one result | {count} results',
    'common_search_no_results': 'No results found',
    'common_search_no_results_subtitle_1': 'Your search did not match any results.',
    'common_search_no_results_tip_1': 'Check the spelling of your keyword search or start a new search.',
    'common_search_no_results_tip_adv_1': 'Check the spelling of your keyword search, the conditions, the operators or start a new search.',
    'common_search_results': 'Search Results',
    'common_service_status_beta': 'Beta',
    'common_service_status_deprecated': 'Deprecated',
    'common_state_empty': 'There is nothing here',
    'common_state_empty_results': 'No results found',
    'common_state_error_sww': 'Something went wrong',
    'common_state_loading': 'Loading',
    'common_state_not_found': 'Not found',
    'common_status_completed': 'Completed',
    'common_status_in_progress': 'In Progress',
    'Communication': 'Communication',
    'Communication Subscription': 'Communication Subscription',
    'Communication Subscription Management': 'Communication Subscription Management',
    'Communication Subscription submitted': 'Communication Subscription submitted',
    'Communication Subscriptions': 'Communication Subscriptions',
    'Communication Subscriptions are supported only for Emails and Phone Numbers': 'Communication Subscriptions are supported only for Emails and Phone Numbers',
    'COMMUNICATION_SUBSCRIPTION': 'Communication Subscription',
    'Company, Organization, Institution, etc.': 'Company, Organization, Institution, etc.',
    'CONSENT': 'Consent',
    'Consent to Communicate': 'Consent to Communicate',
    'Consent to Communicate (Accept)': 'Consent to Communicate (Accept)',
    'Consent to Process Data - Explicit': 'Consent to Process Data - Explicit',
    'Consent to Process Data - Explicit (Accept)': 'Consent to Process Data - Explicit (Accept)',
    'Consent to Process Data - Implicit': 'Consent to Process Data - Implicit',
    'Contact': 'Contact',
    'CONTACT_DETAIL_INVALID': 'Contact Detail is invalid',
    'CONTACT_DETAIL_INVALID_TYPE': 'Contact Details has invalid type',
    'CONTACT_DETAIL_INVALIDATED_BY_INCIDENT': 'Contact Detail invalidated by incident',
    'CONTACT_DETAIL_MISSING': 'Contact Detail is missing',
    'CONTACT_DETAIL_NOT_VALIDATED': 'Contact Detail could not be validated',
    'CONTACT_DETAIL_NOT_VERIFIED': 'Contact Detail could not be verified',
    'CONTACT_DETAIL_VERIFICATION_FAILED': 'Contact Detail verification failed',
    'CONTAIN': 'contain',
    'Content': 'Content',
    'Content & Design': 'Content & Design',
    'Content and design will be reset.': 'Content and design will be reset.',
    'CONTRACT': 'Contract',
    'Could not retrieve image': 'Could not retrieve image',
    'Country': 'Country',
    'Create ': 'Create ',
    'Create Broadcaster': 'Create Broadcaster',
    'Create Email Campaign': 'Create Email Campaign',
    'Create Email Template': 'Create Email Template',
    'Create Life Event': 'Create Life Event',
    'Create Person': 'Create Person',
    'Create Property': 'Create Property',
    'Create SMS Campaign': 'Create SMS Campaign',
    'create_entity': 'Create {entity}',
    'Created By': 'Created By',
    'Created Date': 'Created Date',
    'Created Date Range': 'Created Date Range',
    'Credits': 'Credits',
    'Custom': 'Custom',
    'Custom Title': 'Custom Title',
    'Date': 'Date',
    'Date of Birth': 'Date of Birth',
    'date_hierarchical_input_notice_1': 'You do not need to indicate a month or a day - only the year is mandatory.',
    'DECEASED': 'Deceased',
    'Deceased': 'Deceased',
    'December': 'December',
    'DECLARED_BUT_NOT_SPECIFIED': 'Not Specified',
    'Default Language': 'Default Language',
    'Default Workspace': 'Default Workspace',
    'Delete Activity?': 'Delete Activity?',
    'Delete Broadcaster?': 'Delete Broadcaster?',
    'Delete Email Template?': 'Delete Email Template?',
    'Delete Property': 'Delete Property',
    'Delete Property Group?': 'Delete Group?',
    'Delete Sender Email Address?': 'Delete Sender Email Address?',
    'Delete Workspace': 'Delete Workspace',
    'Delete Workspace?': 'Delete Workspace?',
    'Deleting Email Template': 'Deleting Email Template',
    'Demographics': 'Demographics',
    'DESC': 'desc',
    'Descending': 'Descending',
    'Description': 'Description',
    'Details': 'Details',
    'df_edit_group_create_new': 'Create new group',
    'df_edit_group_select_existing': 'Select from existing groups',
    'df_edit_pg_pd_listing_no_properties': 'No Properties found in this Group',
    'df_properties_with_count': 'no Properties | 1 Property | {count} Properties',
    'Direct URL': 'Direct URL',
    'Discard changes': 'Discard changes',
    'Discard changes?': 'Discard changes?',
    'Do not communicate': 'Do not communicate',
    'Do you need more information?': 'Do you need more information?',
    'DO_NOT_COMMUNICATE_FLAG': 'Do Not Communicate flag',
    'Domain Name': 'Domain Name',
    'Edit ': 'Edit ',
    'Edit Broadcaster': 'Edit Broadcaster',
    'Edit Email Campaign': 'Edit Email Campaign',
    'Edit Email Template': 'Edit Email Template',
    'Edit Person': 'Edit Person',
    'Edit SMS Campaign': 'Edit SMS Campaign',
    'Edited on': 'Edited on',
    'Education': 'Education',
    'Educational Organization': 'Educational Organization',
    'EL': 'Greek (Ελληνικά)',
    'el_val_form_field_df_pg_pd_name': 'Name is not valid. Examples of valid names: ""firstName"", ""firstName1"", ""first_name_1""',
    'el_val_form_field_df_pg_pd_name_string_must_be_unique_in_list': 'This name already exists',
    'el_val_form_field_email': 'This field must be a valid email address',
    'el_val_form_field_length_max': 'This field must be up to {max} characters long',
    'el_val_form_field_length_min': 'This field must be at least {min} characters long',
    'el_val_form_field_length_min_max': 'This field must be between {min} and {max} characters',
    'el_val_form_field_must_be_string': 'This field must be a string',
    'el_val_form_field_not_blank': 'This field is required',
    'el_val_form_field_required': 'This field is required',
    'el_val_form_field_upper_ascii': 'This field must contain only uppercase Latin characters',
    'Email': 'Email',
    'Email Address': 'Email Address',
    'Email Addresses': 'Email Addresses',
    'Email Campaign': 'Email Campaign',
    'Email Campaign Content & Design updated': 'Email Campaign Content & Design updated',
    'Email Campaign Content and Design': 'Email Campaign Content and Design',
    'Email Campaign created': 'Email Campaign created',
    'Email Campaign deleted': 'Email Campaign deleted',
    'Email Campaigns': 'Email Campaigns',
    'Email Template': 'Email Template',
    'Email Template created': 'Email Template created',
    'Email Template deleted': 'Email Template deleted',
    'Email Template HTML Editor': 'Email Template HTML Editor',
    'Email Template updated': 'Email Template updated',
    'EMAIL_CAMPAIGN_BOUNCE': 'Email Campaign Bounce',
    'EMAIL_CAMPAIGN_CLICK': 'Email Campaign Click',
    'EMAIL_CAMPAIGN_COMPLAINT': 'Email Campaign Complaint',
    'email_campaign_content_desktop_edit_1': 'You can design the Email Campaign from your desktop.',
    'email_campaign_creator_notice_1': 'This action will create a draft Email Campaign.',
    'email_campaign_creator_notice_2': 'No messages will be sent.',
    'email_campaign_creator_notice_3': 'Name and description are explanatory fields and cannot be changed.',
    'EMAIL_CAMPAIGN_DELIVERY': 'Email Campaign Delivery',
    'email_campaign_desktop_edit_1': 'You can edit the Email Campaign from your desktop.',
    'EMAIL_CAMPAIGN_OPEN': 'Email Campaign Open',
    'EMAIL_CAMPAIGN_REJECT': 'Email Campaign Reject',
    'EMAIL_CAMPAIGN_RENDERING_FAILURE': 'Email Campaign Rendering Failure',
    'EMAIL_CAMPAIGN_SEND': 'Email Campaign Send',
    'EMAIL_LOG': 'Logged Email',
    'email_template_desktop_edit_1': 'You can edit the Email Template from your desktop.',
    'EMPTY': 'empty',
    'EN': 'English',
    'END_WITH': 'end with',
    'Engagement': 'Engagement',
    'Enter internal value': 'Enter internal value',
    'Enter label': 'Enter label',
    'entity Activity': 'Activity | Activities',
    'entity Branding': 'Branding | Brandings',
    'entity Broadcaster': 'Broadcaster | Broadcasters',
    'entity Campaign': 'Campaign | Campaigns',
    'entity Data Privacy and Consent': 'Data Privacy and Consent | Data Privacy and Consent',
    'entity Email Campaign': 'Email Campaign | Email Campaigns',
    'entity Email Template': 'Email Template | Email Templates',
    'entity Person': 'Person | People',
    'entity Sender Email Address': 'Sender Email Address | Sender Email Addresses',
    'entity SMS Campaign': 'SMS Campaign | SMS Campaigns',
    'entity Workspace': 'Workspace | Workspaces',
    'entity Workspace Type': 'Workspace Type | Workspace Types',
    'EQUAL': 'equal',
    'Example': 'Example',
    'Explanation': 'Explanation',
    'Extra Bold': 'Extra Bold',
    'Family': 'Family',
    'February': 'February',
    'File Import': '@:FILE_IMPORT',
    'FILE_IMPORT': 'File Import',
    'Filters': 'Filters',
    'First Color Accent': 'First Color Accent',
    'First Met': 'First Met',
    'First Name': 'First Name',
    'Font': 'Font',
    'Font Weight': 'Font Weight',
    'Footer': 'Footer',
    'From Email': 'From Email',
    'From Name': 'From Name',
    'General': 'General',
    'Getting Started': 'Getting Started',
    'Go to start page': 'Go to start page',
    'Graduated': 'Graduated',
    'GREATER_THAN': 'greater than',
    'GREATER_THAN_EQUAL': 'greater than equal',
    'Group': 'Group',
    'Group Internal Name': 'Group Internal Name',
    'Group Label': 'Group Label',
    'Groups': 'Groups',
    'Header': 'Header',
    'Height': 'Height',
    'Home': 'Home',
    'Home & Living': 'Home & Living',
    'HTML': 'HTML',
    'HTML Editor': 'HTML Editor',
    'ID': 'ID',
    'Image': 'Image',
    'Image Link': 'Image Link',
    'Import design by Email Template': 'Import design by Email Template',
    'IN': 'in',
    'INCOMPLETE_INFORMATION': 'Incomplete Information',
    'INDIVIDUAL': 'Individual',
    'Inner Spacing': 'Inner Spacing',
    'Interests & Activities': 'Interests & Activities',
    'Internal Name': 'Internal Name',
    'Internal Value': 'Internal Value',
    'Invalid': 'Invalid',
    'Item': 'Item | Items',
    'Item #': 'Item #',
    'January': 'January',
    'July': 'July',
    'June': 'June',
    'Justify': 'Justify',
    'Label': 'Label',
    'Language': 'Language',
    'Last Modified By': 'Last Modified By',
    'Last Modified Date': 'Last Modified Date',
    'Last Modified Date Range': 'Last Modified Date Range',
    'Last Name': 'Last Name',
    'Lawful Basis': 'Lawful Basis',
    'Left': 'Left',
    'Left & Right': 'Left & Right',
    'Left Job': 'Left Job',
    'Left Right': 'Left Right',
    'Left School': 'Left School',
    'legacyGpUiUserRecord_person:person_left_sidebar_summary_card': 'Customize left sidebar',
    'Legal': 'Legal',
    'Legitimate Interest': 'Legitimate Interest',
    'LEGITIMATE_INTERESTS': 'Legitimate Interests',
    'LESS_THAN': 'less than',
    'LESS_THAN_EQUAL': 'less than equal',
    'Life Events': 'Life Events',
    'Light': 'Light',
    'Limit': 'Limit',
    'Line Height': 'Line Height',
    'Link': 'Link',
    'Links': 'Links',
    'Location': 'Location',
    'Logo': 'Logo',
    'Logo (Favicon)': 'Logo (Favicon)',
    'Logo (Icon)': 'Logo (Icon)',
    'Logo (Square)': 'Logo (Square)',
    'Loss of a Loved One': 'Loss of a Loved One',
    'Manage Broadcasters': 'Manage Broadcasters',
    'March': 'March',
    'MARKETING_CALL': 'Marketing Call',
    'MARKETING_EMAIL': 'Marketing Email',
    'MARKETING_SMS': 'Marketing SMS',
    'Marriage': 'Marriage',
    'Matches': 'Matches',
    'Max Communication Subscriptions per Import': 'Max Communication Subscriptions per Import',
    'Max People per Export': 'Max People per Export',
    'Max People per Import': 'Max People per Import',
    'Max Recipients per Email Campaign': 'Max Recipients per Email Campaign',
    'Max Recipients per SMS Campaign': 'Max Recipients per SMS Campaign',
    'May': 'May',
    'Menu': 'Menu',
    'Metadata': 'Metadata',
    'Milestones & Achievements': 'Milestones & Achievements',
    'Moved': 'Moved',
    'Name': 'Name',
    'Naming': 'Naming',
    'Naming VOC': 'Naming VOC',
    'Navigation Bar': 'Navigation Bar',
    'Navigation Bar with Logo': 'Navigation Bar with Logo',
    'New Call Log': 'New Call Log',
    'New Child': 'New Child',
    'New Communication Subscription': 'New Communication Subscription',
    'New Email Log': 'New Email Log',
    'New Group': 'New Group',
    'New Job': 'New Job',
    'New Message': 'New Message',
    'New Note': 'New Note',
    'New Pet': 'New Pet',
    'New Property': 'New Property',
    'New Relationship': 'New Relationship',
    'New Roommate': 'New Roommate',
    'New School': 'New School',
    'New Task': 'New Task',
    'New Vehicle': 'New Vehicle',
    'No': 'No',
    'no': 'no',
    'No data': 'No data',
    'No matching data': 'No matching data',
    'No Repeat': 'No Repeat',
    'no_sender_email_addresses_1': 'There are no verified email addresses',
    'Normal': 'Normal',
    'Not Found': 'Not Found',
    'NOT_ALL': 'not all',
    'NOT_APPLICABLE': 'Not Applicable',
    'NOT_BETWEEN': 'not between',
    'NOT_CONTAIN': 'not contain',
    'NOT_EMPTY': 'not empty',
    'NOT_END_WITH': 'not end with',
    'NOT_EQUAL': 'not equal',
    'NOT_IN': 'not in',
    'NOT_OPTED_IN_OR_OUT': 'Not Opted In',
    'NOT_SPECIFIED': 'Not Specified',
    'NOT_START_WITH': 'not start with',
    'NOTE': 'Note',
    'November': 'November',
    'Occupation': 'Occupation',
    'October': 'October',
    'of_the_following_and': 'of the following',
    'of_the_following_or': 'of the following',
    'Offline Sources': '@:OFFLINE_SOURCES',
    'OFFLINE_SOURCES': 'Offlne Sources',
    'ONE_TO_ONE_CALL': 'Personal Call',
    'ONE_TO_ONE_EMAIL': 'Personal Email',
    'ONE_TO_ONE_SMS': 'Personal SMS',
    'Online Sources': '@:ONLINE_SOURCES',
    'ONLINE_SOURCES': 'Online Sources',
    'OPTED_IN': 'Opted In',
    'OPTED_OUT': 'Opted Out',
    'Options': 'Options',
    'OR': 'or',
    'or_any': 'any',
    'ORGANIZATION': 'Organization',
    'Origin': 'Origin',
    'OTHER': 'Other',
    'Outer Spacing': 'Outer Spacing',
    'Parenthood': 'Parenthood',
    'pd_edit_label_Personalization': 'Personalization',
    'pd_edit_label_Queryable': 'Queryable',
    'pd_edit_title_Advanced': 'Advanced',
    'pd_edit_title_Basics': 'Basics',
    'People': 'People',
    'People Explorer': 'People Explorer',
    'People per page': 'People per page',
    'people_first_use_btn_prompt_1': 'Add new Person',
    'people_first_use_prompt_mc_1': 'Get started with Cloutlayer by adding a new Person!',
    'people_first_use_subtitle_1': 'it seems that you haven\'t created any People yet.',
    'Person': 'Person',
    'Person created': 'Person created',
    'Person deleted': 'Person deleted',
    'Person updated': 'Person updated',
    'person_activity_action_call_log': 'Call Log',
    'person_activity_action_com_sub': 'Communication Subscription',
    'person_activity_action_email_log': 'Email Log',
    'person_activity_action_note': 'Note',
    'person_activity_action_task': 'Task',
    'person_com_sub_no_identity_profiles': 'This person has no Identity Profiles (contact or/and digital details)',
    'PERSON_CREATE': 'Person Create',
    'person_create_edit_gdpr_statement_checkbox': 'I have a valid lawful basis for processing Person\'s data.',
    'person_creator_gdpr_notice': 'I have legal basis for processing person\'s data.',
    'person_creator_gdpr_notice_lawful_basis': 'Legitimate Interest, Performance of a contract, Freely given consent from contact or Not applicable',
    'person_creator_validation_at_least_one_identifying': 'Person must have at least one identifying field completed',
    'PERSON_DELETE': 'Person Delete',
    'PERSON_UPDATE': 'Person Update',
    'Phone Number': 'Phone Number',
    'Please contact support': 'Please contact support',
    'Please enter a keyword': 'Please enter a keyword',
    'Please enter Workspace name': 'Please enter Workspace name',
    'Portal': '@:PORTAL',
    'PORTAL': 'Portal',
    'Priority': 'Priority',
    'Privacy Policy': 'Privacy Policy',
    'Privacy Policy URL': 'Privacy Policy URL',
    'pro_def_edit_modal_create_delete_notice_1': 'This action cannot be undone',
    'pro_def_edit_modal_create_delete_notice_2': 'It will take some time until this process completes',
    'pro_def_edit_modal_create_notice_1': 'Property cannot be changed after creation',
    'pro_def_edit_modal_delete_notice_1': 'Data will be deleted permanently',
    'Profile': 'Profile',
    'Promotion': 'Promotion',
    'prop_def_edit_enumeration_notice_1': 'The first option is used as default value in forms.',
    'prop_def_edit_enumeration_notice_2': 'Only one label can be empty in order to declare no value is forms. Although its internal value is required.',
    'prop_def_edit_form_enumValues_labels_unique': 'Options labels {valuesListLiteral} must be unique',
    'prop_def_edit_form_enumValues_max_length': 'Options labels and internal values must be up to {max} characters long',
    'prop_def_edit_form_enumValues_value_invalid_regex': 'Options contain invalid internal values {valuesListLiteral}. Examples of valid internal values: ""BASIC"", ""SOCIAL_MEDIA"", ""SOCIAL_MEDIA_1"", ""SOCIALMEDIA1"".',
    'prop_def_edit_form_enumValues_values_unique': 'Options internal values {valuesListLiteral} must be unique',
    'Property Definition created': 'Property created',
    'Property Definition deleted': 'Property deleted',
    'Property Definition updated': 'Property updated',
    'Property Group created': 'Group created',
    'Property Group deleted': 'Group deleted',
    'PROPERTY_CHANGE_EMAIL': 'Email Change',
    'PROPERTY_CHANGE_MOBILIZER': 'Mobilizer Change',
    'PROPERTY_CHANGE_PHONE_NUMBER': 'Phone Number Change',
    'PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL': 'Inferred Support Level Change',
    'PROPERTY_CHANGE_SUPPORT_LEVEL': 'Support Level Change',
    'PROPERTY_CHANGE_SUPPORT_STATUS': 'Support Status Change',
    'PROPERTY_CHANGE_TAXONOMY_LABELS': 'Labels Change',
    'PROPERTY_CHANGE_TAXONOMY_PRIORITY': 'Priority Change',
    'PROPERTY_CHANGE_VOLUNTEERING_STATUS': 'Volunteering Status Change',
    'Query Builder': 'Query Builder',
    'quotas_reach_limit_1': 'You\'ve reached the limit of {limit} {itemWord} available for your account',
    'Range': 'Range',
    'read_only_definition_and_predefined_property_warning_1': 'This property is provided by Cloutlayer and cannot be edited.',
    'Reasons': 'Reasons',
    'Receive Call Campaign': 'Receive Call Campaign',
    'Receive Email Campaign': 'Receive Email Campaign',
    'Receive One To One Call': 'Receive One To One Call',
    'Receive One To One Email': 'Receive One To One Email',
    'Receive One To One SMS': 'Receive One To One SMS',
    'Receive SMS Campaign': 'Receive SMS Campaign',
    'Region': 'Region',
    'Relationship': 'Relationship',
    'Remembrance': 'Remembrance',
    'Renovation': 'Renovation',
    'Repeat': 'Repeat',
    'Repeat X': 'Repeat X',
    'Repeat Y': 'Repeat Y',
    'Reply To Email': 'Reply To Email',
    'Reply To Name': 'Reply To Name',
    'Retirement': 'Retirement',
    'Right': 'Right',
    'Save and continue to Email Campaign': 'Save and continue to Email Campaign',
    'School, University, College, Institution, etc.': 'School, University, College, Institution, etc.',
    'Search or add new label': 'Search or add new label',
    'Second Color Accent': 'Second Color Accent',
    'Section': 'Section',
    'Section Image': 'Section Image',
    'Select a category': 'Select a category',
    'Select all': 'Select all',
    'Select matched': 'Select matched',
    'Send Confirmation Email': 'Send Confirmation Email',
    'Sender Email Address': 'Sender Email Address',
    'Sender Email Address deleted': 'Sender Email Address deleted',
    'sender_email_address_view_subtitle': 'Verify your email addresses and start sending campaigns',
    'September': 'September',
    'Service Credits': 'Credits',
    'Service Limitations': 'Limitations',
    'Service Quotas': 'Quotas',
    'Settings': 'Settings',
    'Settings updated': 'Settings updated',
    'settings_branding': 'Branding',
    'settings_credits': 'Credits',
    'settings_data_privacy_and_consent': 'Data Privacy and Consent',
    'settings_quotas': 'Quotas',
    'settings_tenant_account_update_information': 'Account',
    'Size': 'Size',
    'SMS Campaign': 'SMS Campaign',
    'SMS Campaign created': 'SMS Campaign created',
    'SMS Campaign deleted': 'SMS Campaign deleted',
    'SMS Campaigns': 'SMS Campaigns',
    'SMS Name': 'SMS Name',
    'sms_campaign_creator_notice_1': 'This action will create a draft SMS Campaign.',
    'sms_campaign_creator_notice_2': 'No messages will be sent.',
    'sms_campaign_creator_notice_3': 'Name and description are explanatory fields and cannot be changed.',
    'sms_campaign_desktop_edit_1': 'You can edit the SMS Campaign from your desktop.',
    'SMS_CAMPAIGN_EVENT': 'SMS Campaign',
    'Social': 'Social',
    'sorted by': 'sorted by',
    'Source Description': 'Source Description',
    'Source Type': 'Source Type',
    'Spacing': 'Spacing',
    'start_by_adding_blocks_v1': 'Start by adding <br>modules from the left panel.',
    'START_WITH': 'start with',
    'Status': 'Status',
    'sub_portal_basic_subtitle': 'Perfect for sharing or including in your website or in any other public digital asset',
    'sub_portal_basic_title': 'Basic',
    'sub_portal_one_click_extra_help_par_1': 'These links must have as parameters the Identity Profile Identifier and the Communication Subscription Type.',
    'sub_portal_one_click_extra_help_par_2': 'Optionally, they can have the Campaign Identifier and the preferred Language Key (if i18n add-on is enabled).',
    'sub_portal_one_click_extra_help_real_example': 'This example is valid and can be used as it is in an Email Campaign body.',
    'sub_portal_one_click_subtitle': 'More suitable to targeted campaigns where you need to provide people the option to update their preferences',
    'sub_portal_one_click_title': 'One-Click',
    'sub_portal_sub_email_title': 'Subscribe Email',
    'sub_portal_sub_phone_number_title': 'Subscribe Phone Number',
    'sub_portal_unsub_email_title': 'Unsubscribe Email',
    'sub_portal_unsub_phone_number_title': 'Unsubscribe Phone Number',
    'Subscriptions Portal': 'Subscriptions Portal',
    'subscriptions_portal_subtitle': 'A customizable portal that helps People manage their communication subscriptions in a simple, transparent way',
    'Support': 'Support',
    'Switch editor': 'Switch editor',
    'Switch to email builder?': 'Switch to email builder?',
    'Switch to HTML code editor?': 'Switch to HTML code editor?',
    'TASK': 'Task',
    'Taxonomy': 'Taxonomy',
    'Text': 'Text',
    'Text Color': 'Text Color',
    'Text Line Height': 'Text Line Height',
    'Text Size': 'Text Size',
    'Text Style': 'Text Style',
    'Text Weight': 'Text Weight',
    'The requested Identity Profile does not exist.': 'The requested Identity Profile does not exist.',
    'Timeline': 'Timeline',
    'Timestamp': 'Timestamp',
    'Title': 'Title',
    'toast_email_campaign_in_progress': 'Email Campaign is now in progress!',
    'toast_sender_email_address_created_1': 'An email has been sent to this address. Please follow the instructions',
    'toast_sms_campaign_in_progress': 'SMS Campaign is now in progress!',
    'Tools': 'Tools',
    'tools_domain_definition_person': 'Person Properties',
    'tools_email_templates': 'Email Templates',
    'tools_export': 'Export Data',
    'tools_gdpr_add_on': 'GDPR Add-on',
    'tools_i18n_add_on': 'i18n Add-on',
    'tools_import': 'Import Data',
    'tools_politics_add_on': 'Politics Add-on',
    'tools_sender_email_addresses': 'Sender Email Addresses',
    'tools_subscriptions_portal': 'Subscriptions Portal',
    'Top': 'Top',
    'Top & Bottom': 'Top & Bottom',
    'Top & Bottom / Left & Right': 'Top & Bottom / Left & Right',
    'Top Bottom': 'Top Bottom',
    'Top Bottom Left Right': 'Top Bottom Left Right',
    'Translated Label': 'Translated Label',
    'Travel': 'Travel',
    'Type': 'Type',
    'Type to search': 'Type to search…',
    'UNDER_13_YEARS_OLD': 'Under 13 years old',
    'Unknown': 'Unknown',
    'Unlimited': 'Unlimited',
    'Unselect all': 'Unselect all',
    'Unselect matched': 'Unselect matched',
    'Upload': 'Upload',
    'Usage': 'Usage',
    'User': 'User',
    'Visit our Knowledge Base': 'Visit our Knowledge Base',
    'Website URL': 'Website URL',
    'Weight': 'Weight',
    'Wellness & Health': 'Wellness & Health',
    'Width': 'Width',
    'Work': 'Work',
    'Workplace': 'Workplace',
    'Workspace': 'Workspace',
    'Workspace created': 'Workspace created',
    'Workspace deleted': 'Workspace deleted',
    'Workspace must have a name': 'Workspace must have a name',
    'Workspace name is too long': 'Workspace name is too long',
    'Workspace updated': 'Workspace updated',
    'ws_type_gce_false': 'Basic',
    'ws_type_gce_label': 'Search Type',
    'ws_type_gce_true': 'Advanced',
    'ws_view_COMPACT': 'Compact',
    'ws_view_DEFAULT': 'Default',
    'ws_view_label': 'Density',
    'y.o.': 'y.o.',
    'Yes': 'Yes',
    'yes': 'yes',
    'ZIP Code': 'ZIP Code'
};

export default en;
